import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  LinearProgress,
  FormControl,
  Dialog,
  DialogTitle
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useTitleStyles } from './FeatureComponent';
import { TableDataContext } from '../../tabeditor/tools/Table';

import { makeStyles } from '@material-ui/core/styles';
import { getDateTimeString, getDateTimeValueObject } from './DateTime';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex'
  },
  zonedDateTimeEditor: {
    width: '21.5rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  },
  zonedDateTimeEditorInTable: {
    width: '19rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  },
  timeZoneValue: {
    position: 'relative',
    top: '3.2rem',
    marginLeft: '1rem'
  },
  timeZoneValueInTable: {
    position: 'relative',
    top: '1rem',
    whiteSpace: 'nowrap'
  }
}));

function getTimeZoneString(zonedDateTime) {
  let timeZoneString = '';
  if (zonedDateTime && zonedDateTime.timeZone) {
    timeZoneString = zonedDateTime.timeZone;
  }
  return timeZoneString;
}

export function getZonedDateTimePayloadData(
  dateTimeValue,
  timeZoneValueString
) {
  return {
    dateValue: {
      year: dateTimeValue.year,
      month: dateTimeValue.month,
      day: dateTimeValue.day
    },
    timeValue: { hour: dateTimeValue.hour, minute: dateTimeValue.minute },
    timeZone: timeZoneValueString
  };
}

export const ZonedDateTime = ({
  featureInfo,
  zonedDateTime,
  setValueState,
  saveChangedValue,
  loading,
  error
}) => {
  const { t } = useTranslation();
  const [isInTableContext] = useContext(TableDataContext);
  const classesTitle = useTitleStyles();
  const classes = useStyles();

  const [timeZoneInput, setTimeZoneInput] = useState('');
  const [timeZoneOpen, setTimeZoneOpen] = useState(false);

  const title = isInTableContext ? null : featureInfo.title;
  const mandatory = featureInfo.mandatory;
  const hasDefaultData = featureInfo.hasDefaultData;
  const timeZoneList = featureInfo.featureComponent?.timeZoneList || [];

  const dateTimeValueString = getDateTimeString(zonedDateTime);
  const timeZoneValueString = getTimeZoneString(zonedDateTime);
  const [dateTimeBlurState, setDateTimeBlurState] = useState(
    dateTimeValueString
  );
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    timeZoneList.find((tz) => tz.id === timeZoneValueString) || null
  );

  const isMandatoryError = mandatory && dateTimeValueString == '';
  error = error || '';
  let helper = '';
  if (loading) {
    helper = <LinearProgress color="secondary" />;
  } else if (isMandatoryError && !hasDefaultData) {
    helper = t('common.errorMandatory');
  }

  function saveDateTimeValue(event) {
    const newDateTimeString = event.target.value;

    if (dateTimeBlurState == newDateTimeString || loading) return;
    setDateTimeBlurState(newDateTimeString);

    let newDateTimeValue = null;
    let newZonedTimeValue = null;
    if (newDateTimeString) {
      newDateTimeValue = getDateTimeValueObject(newDateTimeString);
      newZonedTimeValue = {
        ...newDateTimeValue,
        timeZone: selectedTimeZone?.id || ''
      };
    } else if (mandatory && !hasDefaultData) {
      return;
    } else {
      newZonedTimeValue = { timeZone: selectedTimeZone?.id || '' };
    }
    setValueState(newZonedTimeValue);

    if (selectedTimeZone) {
      saveChangedValue(
        newDateTimeValue,
        getZonedDateTimePayloadData(newDateTimeValue, selectedTimeZone.id)
      );
    } else {
      setTimeZoneOpen(true);
    }
  }

  function showError() {
    if (!error) return;
    TOGO.Util.notifyResponse(error, true);
  }

  function saveTimeZone(_, newSelectedTimeZone) {
    if (!newSelectedTimeZone) return;

    setSelectedTimeZone(newSelectedTimeZone);

    let newZonedTimeValue = null;
    let newDateTimeValue = null;

    if (dateTimeValueString) {
      newDateTimeValue = getDateTimeValueObject(dateTimeValueString);
      newZonedTimeValue = {
        ...newDateTimeValue,
        timeZone: newSelectedTimeZone.id
      };

      saveChangedValue(
        newDateTimeValue,
        getZonedDateTimePayloadData(newDateTimeValue, newSelectedTimeZone.id)
      );
    } else {
      newZonedTimeValue = {
        timeZone: newSelectedTimeZone.id
      };
    }

    setValueState(newZonedTimeValue);
  }

  function timeZoneInputChange(_, newInputValue) {
    setTimeZoneInput(newInputValue);
  }

  function timeZoneClose() {
    setTimeZoneOpen(false);
  }

  return (
    <FormControl>
      <div className={classes.wrap}>
        <TextField
          label={title}
          InputLabelProps={{
            shrink: false,
            disableAnimation: true,
            className: classesTitle.title
          }}
          className={
            isInTableContext
              ? classes.zonedDateTimeEditorInTable
              : classes.zonedDateTimeEditor
          }
          variant={isInTableContext ? 'standard' : 'outlined'}
          value={dateTimeValueString}
          type="datetime-local"
          error={Boolean(error) || (isMandatoryError && !hasDefaultData)}
          FormHelperTextProps={{ component: 'div' }}
          helperText={helper}
          onMouseEnter={showError}
          onChange={saveDateTimeValue}
        />
        <Autocomplete
          options={timeZoneList}
          getOptionLabel={(option) => option.label}
          size="small"
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          value={selectedTimeZone}
          onChange={saveTimeZone}
          inputValue={timeZoneInput}
          onInputChange={timeZoneInputChange}
          getOptionSelected={(option, value) => option.id === value.id}
        />
      </div>
    </FormControl>
  );
};
